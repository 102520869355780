import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import './PageHeader.scss';

function PageHeader({ title, description, buttons }) {
  return (
    <div className="page-header">
      <div className="header-content-wrapper"> 
        <h2>{ title }</h2>
        <p>{ description }</p>
        {buttons &&
          <div className="btn-wrapper">
            {_.map(buttons, button => (
              <Link className="btn" to={button.link}>{button.title}</Link>
            )) }
          </div>
        }
      </div>
    </div>
  );
}

export default PageHeader;