const radioSet = [
    {
      id: 1,
      trackUrl: 'https://soundcloud.com/melodicdiggers/premiere-bog-gheist-venere-fideles-remix-atlant',
    },
    {
      id: 2,
      trackUrl: 'https://soundcloud.com/wurtz01/florence-the-machine-jenny-of',
    },
    {
      id: 3,
      trackUrl: 'https://soundcloud.com/sweetspace/premiere-andre-texias-agony-original-mix',
    },
    {
      id: 4,
      trackUrl: 'https://www.youtube.com/watch?v=eK9NS6mkk-E',
    },
    {
      id: 5,
      trackUrl: 'https://www.youtube.com/watch?v=gme-QwflM9o',
    },
    {
      id: 6,
      trackUrl: 'https://www.youtube.com/watch?v=4s26H9zhXwY',
    },
    {
      id: 7,
      trackUrl: 'https://www.youtube.com/watch?v=0ABA9tIqCCo',
    },
    {
      id: 8,
      trackUrl: 'https://www.youtube.com/watch?v=hCddpMwMDQU',
    },
    {
      id: 9,
      trackUrl: 'https://www.youtube.com/watch?v=CQrNFlRBQQQ',
    },
    {
      id: 10,
      trackUrl: 'https://www.youtube.com/watch?v=CWrWHC79Wnw',
    },
    {
      id: 11,
      trackUrl: 'https://www.youtube.com/watch?v=jdrFFhai6z8',
    },
    {
      id: 12,
      trackUrl: 'https://www.youtube.com/watch?v=wuKOBOz23nw',
    },
    {
      id: 13,
      trackUrl: 'https://www.youtube.com/watch?v=NzanMCbtjos',
    },
    {
      id: 14,
      trackUrl: 'https://www.youtube.com/watch?v=O_vOoZH9w1g',
    },
    {
      id: 15,
      trackUrl: 'https://www.youtube.com/watch?v=qIY9boEt1uM',
    },
    {
      id: 16,
      trackUrl: 'https://www.youtube.com/watch?v=E9Abz9lpS_8',
    },
    {
      id: 17,
      trackUrl: 'https://www.youtube.com/watch?v=UUwNhihXZsU',
    },
    {
      id: 18,
      trackUrl: 'https://www.youtube.com/watch?v=4r2NN5x0wBI',
    },
    {
      id: 19,
      trackUrl: 'https://www.youtube.com/watch?v=90g6XzZYz8E',
    },
    {
      id: 20,
      trackUrl: 'https://www.youtube.com/watch?v=A9XXEMdD0dQ',
    },
    {
      id: 21,
      trackUrl: 'https://www.youtube.com/watch?v=RqwNiOumvFM',
    },
    {
      id: 22,
      trackUrl: 'https://www.youtube.com/watch?v=Qd0x5hP6tXE',
    },
    {
      id: 23,
      trackUrl: 'https://www.youtube.com/watch?v=zUp4y5aScM0',
    },
    {
      id: 24,
      trackUrl: 'https://www.youtube.com/watch?v=N3mJJ2ifzXY',
    },
    {
      id: 25,
      trackUrl: 'https://www.youtube.com/watch?v=yWOAfk21FQg',
    },
    {
      id: 26,
      trackUrl: 'https://www.youtube.com/watch?v=cWRYHSeKUXM',
    },
    {
      id: 27,
      trackUrl: 'https://www.youtube.com/watch?v=LjKbMHuBQG8',
    },
    {
      id: 28,
      trackUrl: 'https://www.youtube.com/watch?v=Vo5GeeVxK34',
    },
    {
      id: 29,
      trackUrl: 'https://www.youtube.com/watch?v=lYylgam-UIo',
    },
    {
      id: 30,
      trackUrl: 'https://www.youtube.com/watch?v=nG8y1S9R-gs',
    },
    {
      id: 31,
      trackUrl: 'https://www.youtube.com/watch?v=aLEPf5B9O6U',
    },
    {
      id: 32,
      trackUrl: 'https://www.youtube.com/watch?v=WuZJITmGuOc',
    },
    {
      id: 33,
      trackUrl: 'https://www.youtube.com/watch?v=Urhqtc_nWk8',
    },
    {
      id: 34,
      trackUrl: 'https://www.youtube.com/watch?v=THh974v9xqo',
    },
    {
      id: 35,
      trackUrl: 'https://www.youtube.com/watch?v=Ew9eTr5p7_I',
    },
    {
      id: 36,
      trackUrl: 'https://www.youtube.com/watch?v=dV7qjDYK6B0',
    },
    {
      id: 37,
      trackUrl: 'https://www.youtube.com/watch?v=BAtskIHG0dM',
    },
    {
      id: 38,
      trackUrl: 'https://www.youtube.com/watch?v=hFonetOA0Ok',
    },
    {
      id: 39,
      trackUrl: 'https://www.youtube.com/watch?v=T0Fcio5Hr0g',
    },
    {
      id: 40,
      trackUrl: 'https://www.youtube.com/watch?v=fkqW3DHW4dM',
    },
    {
      id: 41,
      trackUrl: 'https://www.youtube.com/watch?v=sTyvkA4o3Kc',
    },
    {
      id: 42,
      trackUrl: 'https://www.youtube.com/watch?v=WvsL8Lbhtqg',
    },
];

export default radioSet