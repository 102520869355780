import React from 'react';
import { PageHeader } from '../PageHeader';

function HomePage() {
  const buttons = [
    { title: 'Słuchaj setów Happiness', link: '/happiness' },
    { title: 'Słuchaj radia Happiness', link: '/radio' }
  ]
  return (
    <div className="Home">
      <PageHeader title="Strona główna" description="Witaj na stronie głównej!" buttons={buttons}/>
    </div>
  );
}

export default HomePage;