import React from 'react';
import './SingleTrack.scss';

import play from './img/play.svg';

function SingleTrack({ id, playing, load, trackUrl, cover, title, artist }) {
  return (
    <div className="single-track">
      { id && <span>{id}</span> }
      <div className="cover-wrapper" style={{ backgroundImage: `url(${cover})` }}>
        <div className="cover-wrapper-overlay">
          <div className="play-pause-btn">
            <img src={playing ? play : play} alt="" onClick={() => load(trackUrl, cover, title, artist)}/>
          </div>
        </div>
      </div>
      <div className="description-wrapper">
        <div className="description-wrapper-content">
          <h3>{ title }</h3>
          <p>{ artist }</p>
        </div>
      </div>
    </div>
  );
}

export default SingleTrack;