import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
} from "react-router-dom";
import SVG from 'react-inlinesvg';

import { HappinessPage } from '../HappinessPage';
import { HomePage } from '../HomePage';
import { RadioPage } from '../RadioPage';
import { Toplist } from '../Toplist';
import Duration from './Duration';
import radioSet from '../RadioPage/tracks';

import firstCover from '../HappinessPage/img/cover-5.jpg'
import home from './img/icons/home.svg';
import music from './img/icons/music.svg';
import radio from './img/icons/radio.svg';
import eq from './img/icons/eq.svg';
import volumeIcon from './img/icons/volume.svg';
import pause from './img/icons/pause.svg';
import play from './img/icons/play-2.svg';

import './App.scss';

function App() {
  const [playing, setPlaying] = useState(false);
  const [isRadio, setRadio] = useState(false);
  const [isRadioEnded, setIsRadioEnded] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  const [cover, setCover] = useState(firstCover);
  const [url, setUrl] = useState('http://mbsolutions.pl/happiness/happiness005.wav');
  const [title, setTitle] = useState('Happiness 005');
  const [artist, setArtist] = useState('dobrowolski_');
  const [state, setState] = useState({
    playedSeconds: 0,
    played: 0,
    loadedSeconds: 0,
    loaded: 0
  });
  const [volume, setVolume] = useState('0.8');
  const player = useRef(null);

  const handlePlay = () => { setPlaying(true); }

  const handlePlayPause = () => {
    setPlaying(!playing);
  }

  const handleSeekMouseDown = e => { setSeeking(true); }

  const handleSeekChange = e => { setPlayed(parseFloat(e.target.value)); }

  const handleSeekMouseUp = e => {
    setSeeking(false);
    player.current.seekTo(parseFloat(e.target.value));
  }

  const handleDuration = (duration) => { setDuration(duration); }

  const handleProgress = state => { setState(state); }

  const handleVolumeChange = e => { setVolume(parseFloat(e.target.value)); }

  const handleEnded = () => {
    if(isRadio === true) {
      setIsRadioEnded(true);
      loadRadio(radioSet[Math.floor(Math.random() * radioSet.length)].trackUrl, 'https://i.pinimg.com/originals/d7/85/ef/d785efcc4065a7f7f51121797666b92f.jpg', 'tytuł', 'artysta');
    } else {
      setIsRadioEnded(false);
    }
  }

  const load = (checkedUrl, cover, title, artist) => {
    setRadio(false);
    setUrl(checkedUrl);
    setCover(cover);
    setTitle(title);
    setArtist(artist);
    setPlayed(0);
    setPlaying(true);
  }

  const loadRadio = (checkedUrl, cover, title, artist) => {
    setRadio(true);
    setUrl(checkedUrl);
    setCover(cover);
    setTitle(title);
    setArtist(artist);
    setPlayed(0);
    setPlaying(true);
  }

  return (
    <div className="app">
      <Router>
        <div className="content-wrapper">
          <aside className="aside">
            <div className="logo-wrapper">
              <h1>Happiness Radio</h1>
            </div>
            <div className="menu">
              <ul>
                <li><NavLink exact to="/" activeClassName="active"><SVG src={home}/>Home</NavLink></li>
                <li><NavLink exact to="/happiness" activeClassName="active"><SVG src={music}/>Hapiness</NavLink></li>
                <li><NavLink exact to="/radio" activeClassName="active"><SVG src={radio}/>Radio</NavLink></li>
              </ul>
            </div>
            <Toplist
              playing={playing}
              load={load}
            />
          </aside>
          <main className="main">
            <Route exact path="/">
              <HomePage/>
            </Route>
            <Route exact path="/happiness">
              <HappinessPage
                playing={playing}
                load={load}
              />
            </Route>
            <Route exact path="/radio">
              <RadioPage
                playing={playing}
                loadRadio={loadRadio}
              />
            </Route>
          </main>
        </div>
        <div className="player">
          <SVG src={eq} alt=""/>
          <img src={cover} alt=""/>
          <div className="information">
            <p className="title">{title}</p>
            <p className="artist">{artist}</p>
          </div>
          <div className="controls">
            <SVG src={playing ? pause : play} alt="" onClick={handlePlayPause}/>
          </div>
          {!isRadio && <p className="playedTime"><Duration seconds={state.playedSeconds} /></p>}
          <ReactPlayer 
            url={url}
            playing={playing}
            onPlay={handlePlay}
            onDuration={handleDuration}
            onProgress={handleProgress}
            width={0}
            height={0}
            volume={volume}
            onEnded={handleEnded}
            ref={player}
          />
          {!isRadio &&
            <>
              <input
                className='durationRage'
                type='range' min={0} max={1} step='any'
                value={played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
              />
              <p className="wholeTime"><Duration seconds={duration} /></p>
            </>
          }
          <div className="volumeRage-wrapper">
            <SVG src={volumeIcon} alt=""/>
            <input className='volumeRage' type='range' min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} />
          </div>
        </div>  
      </Router>
    </div>
  );
}

export default App;
