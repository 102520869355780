import React from 'react';
import { PageHeader } from '../PageHeader';
import play from './img/play.svg';
import pause from './img/pause.svg';
import radioSet from './tracks';

function RadioPage({ playing, loadRadio }) {
  const cover = 'https://i.pinimg.com/originals/d7/85/ef/d785efcc4065a7f7f51121797666b92f.jpg';
  const title = 'Radio Happiness';
  const artist = 'Różni artyści';

  return (
    <div className="radio-page">
      <PageHeader title="Happiness Radio" description="Posłuchaj Radia Happiness"/>
      <div className="button-wrapper">
        <button className="btn" onClick={() => loadRadio(radioSet[Math.floor(Math.random() * radioSet.length)].trackUrl, cover, title, artist)}>Słuchaj radia</button>
      </div>
    </div>
  );
}

export default RadioPage;