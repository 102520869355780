import React from 'react';
import _ from 'lodash';
import { PageHeader } from '../PageHeader';
import { SingleTrack } from '../SingleTrack';

import cover1 from './img/cover-1.jpg';
import cover2 from './img/cover-2.jpg';
import cover3 from './img/cover-3.jpg';
import cover4 from './img/cover-4.jpg';
import cover5 from './img/cover-5.jpg';

function HappinessPage({ playing, load }) {
  const happinessSet = [
    {
      id: 1,
      trackUrl: 'http://mbsolutions.pl/happiness/happiness001.mp3',
      cover: cover1,
      title: 'Happiness 001',
      artist: 'Dobrowolski'
    },
    {
      id: 2,
      trackUrl: 'http://mbsolutions.pl/happiness/happiness002.wav',
      cover: cover2,
      title: 'Happiness 002',
      artist: 'Dobrowolski'
    },
    {
      id: 3,
      trackUrl: 'http://mbsolutions.pl/happiness/happiness003.mp3',
      cover: cover3,
      title: 'Happiness 003',
      artist: 'Dobrowolski'
    },
    {
      id: 4,
      trackUrl: 'http://mbsolutions.pl/happiness/happiness004.wav',
      cover: cover4,
      title: 'Happiness 004',
      artist: 'Dobrowolski'
    },
    {
      id: 4,
      trackUrl: 'http://mbsolutions.pl/happiness/happiness005.wav',
      cover: cover5,
      title: 'Happiness 005',
      artist: 'Dobrowolski'
    }
  ];
  return (
    <div className="happiness-page">
      <PageHeader title="Happiness" description="Posłuchaj kultowych setów Happiness" buttons=""/>
      <div className="tracks-wrapper">
        {_.map(happinessSet, track => (
          <SingleTrack 
            playing={playing}
            load={load}
            trackUrl={track.trackUrl}
            cover={track.cover}
            title={track.title}
            artist={track.artist}
            key={track.id}
          />
        )) }
      </div>
    </div>
  );
}

export default HappinessPage;