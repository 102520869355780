import React from 'react';
import _ from 'lodash';
import { SingleTrack } from '../SingleTrack';
import './Toplist.scss';

import cover1 from './img/1.jpg';
import cover2 from './img/2.jpg';
import cover3 from './img/3.jpg';
import cover4 from './img/4.jpg';
import cover5 from './img/5.jpg';

function Toplist({ playing, load }) {
  const toplistTracks = [
    {
      id: '01',
      trackUrl: 'https://www.youtube.com/watch?v=eK9NS6mkk-E',
      cover: cover1,
      title: 'Running',
      artist: 'Colyn feat Maurits Colijn'
    },
    {
      id: '02',
      trackUrl: 'https://www.youtube.com/watch?v=CWrWHC79Wnw',
      cover: cover2,
      title: 'Fresen',
      artist: 'Bonk'
    },
    {
      id: '03',
      trackUrl: 'https://www.youtube.com/watch?v=qIY9boEt1uM',
      cover: cover3,
      title: 'Road To Nowhere',
      artist: 'Kas:st'
    },
    {
      id: '04',
      trackUrl: 'https://www.youtube.com/watch?v=Kxs5kjyqRwA',
      cover: cover4,
      title: 'Lost',
      artist: 'Hunter/Game'
    },
    {
      id: '05',
      trackUrl: 'https://www.youtube.com/watch?v=hFonetOA0Ok',
      cover: cover5,
      title: 'Reasons',
      artist: 'YAIDE, Zacharias Tiempo & Maria Caan'
    },
  ];
  return (
    <div className="toplist">
      <h4>Toplista</h4>
      {_.map(toplistTracks, track => (
          <SingleTrack
            id={track.id}
            playing={playing}
            load={load}
            trackUrl={track.trackUrl}
            cover={track.cover}
            title={track.title}
            artist={track.artist}
            key={track.id}
          />
        )) }
    </div>
  );
}

export default Toplist;